<template>
  <section>
    <p class="grey--text">
      {{$_strings.order.CREATE_DATE}} : {{dateFormat(shipment.orderDate)}}
    </p>
    <v-card>
      <v-card-text>
        <goods-information :shipment="shipment" />
        <units-information
          v-if="$route.query.serviceType === 'LCL'"
          :shipment="shipment"
        ></units-information>
        <v-divider class="mt-2 mb-4"></v-divider>
        <v-row>
          <v-col cols="12" sm="6">
            <location-details
              :shipmentItem="shipment.originalLocation"
              :shipmentsDetails="shipment.multiPick"
              :title="$_strings.order.ORIGIN"
              :picNameTitle="$_strings.order.NAME_SENDER"
              :picPhoneTitle="$_strings.order.PHONE_NUMBER_SENDER"
              :picNoteTitle="$_strings.order.SENDER_NOTE"
              type="ORIGIN"
            ></location-details>
          </v-col>
          <v-col cols="12" sm="6">
            <location-details
              :shipmentItem="shipment.destinationLocation"
              :shipmentsDetails="shipment.multiDrop"
              :title="$_strings.order.DESTINATION"
              :picNameTitle="$_strings.order.RECEPIENTS_NAME"
              :picPhoneTitle="$_strings.order.PHONE_NO_RECIPIENT"
              :picNoteTitle="$_strings.order.RECIPIENT_NOTE"
              type="DESTINATION"
            ></location-details>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
import { dateFormat, timeFormat } from '@/helper/commonHelpers';

const GoodsInformation = () => import(/* webpackChunkName: "GoodsInformation" */ './GoodsInformation.vue');
const LocationDetails = () => import(/* webpackChunkName: "LocationDetails" */ './LocationDetails.vue');
const UnitsInformation = () => import(/* webpackChunkName: "UnitsInformation" */ './UnitsInformation.vue');

export default {
  components: {
    GoodsInformation,
    LocationDetails,
    UnitsInformation,
  },
  props: {
    shipment: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    dateFormat,
    timeFormat,
  },
};
</script>
